<template>
  <el-card class="v_user_list">
    <div class="g-flex"  slot="header">
      <span>商家列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="addBussiness()">新增商家</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="商家状态">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="禁用" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="店铺名字">
         <el-input v-model="query.shop_title" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入店铺名字" clearable></el-input>
      </el-form-item>
      <!-- TODO -->
      <el-form-item label="商家推广码">
         <el-input v-model="query.spread_code" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入商家推广码" clearable></el-input>
      </el-form-item>
      <!-- <el-form-item label="时间：">
          <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>   -->
      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="商家ID" value="user_id"></el-option>
          <el-option label="手机号" value="mobile"></el-option>
        </el-select>
        <el-row>
          <el-col :span="14">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="info" @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="商家id">
          <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
          </template>
      </el-table-column>
        <el-table-column label="店铺" width="160" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{scope.row.shop_title}}</span>
          </template>
      </el-table-column>
      <el-table-column label="商家手机号" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.mobile }}</span>
          </template>
      </el-table-column>
      <el-table-column label="商家余额" width="160" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="g-flex-align-center v-user-money-item-edit">
            <span class="g-blue">{{ scope.row.principal }}</span>
            <i class="el-icon-edit" @click="changeMoneyClick(scope.row)"></i>
          </div>
          </template>
      </el-table-column>
      <el-table-column label="代理" width="150" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="g-flex-align-center v-user-money-item-edit">
            <span class="g-red">{{ scope.row.spread | filtersSpread }}</span>
          </div>
          </template>
      </el-table-column>

      <!-- <el-table-column label="QQ号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.qq }}</span>
          </template>
      </el-table-column>
      <el-table-column label="微信" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.wechat }}</span>
          </template>
      </el-table-column> -->
      <el-table-column label="推广码" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.spread_code }}</span>
          </template>
      </el-table-column>
      <el-table-column label="推广状态" width="80" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <!--  active-text="开启" inactive-text="关闭" -->
            <el-switch v-model="scope.row.spread_status" @change="switchChange(scope.row)" :active-value="1" :inactive-value="0"  active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </template>
      </el-table-column>

      <el-table-column label="商返状态" width="80" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <!--  active-text="开启" inactive-text="关闭" -->
            <el-switch v-model="scope.row.fk_mode" @change="apiShopUserEditHandel(scope.row)" :active-value="1" :inactive-value="0"  active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </template>
      </el-table-column>

      <el-table-column label="状态" width="70" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span class="g-red" v-if="scope.row.status == 0">禁用</span>
            <span class="g-green" v-if="scope.row.status == 1">正常</span>
          </template>
      </el-table-column>
      <!-- <el-table-column label="最后登录时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{formatDate(scope.row.login_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
      </el-table-column> -->
      <el-table-column label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="apiGetUerLoginHandel(scope.row)">登录</el-button>
          <el-button size="mini" type="info" v-if="scope.row.status == 1" @click="apiSetShopStatusHandel(scope.row, 0)">封号</el-button>
          <el-button size="mini" type="primary" v-if="scope.row.status == 0" @click="apiSetShopStatusHandel(scope.row, 1)">解封</el-button>
          <el-button size="mini" type="danger" @click="setForm(scope.row, 2)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗 -->
    <bussiness-list-pop ref="BussinessListPop" :shopTypeList="shopTypeList" @emitSaveSuccess="getList(false)"/>

    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <bussiness-change-money-pop ref="BussinessChangeMoneyPop" @emitSaveSuccess="getList(false)"/>

    <!-- 新增商家 -->
    <bussiness-list-add-pop ref="BussinessListAddPop" @emitSaveSuccess="getList(false)"/>
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetBussinessList, apiSetShopSpreadStatus, apiSetShopStatus, apiGetShopTypeList, apiGetUerLogin, apiShopUserEdit } from '@/utils/api.js'
  import BussinessListPop from './BussinessListPop.vue'
  import BussinessChangeMoneyPop from './BussinessChangeMoneyPop.vue'
  import BussinessListAddPop  from './BussinessListAddPop.vue'
  export default {
    components: { BussinessListPop, BussinessChangeMoneyPop, BussinessListAddPop },
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        query: { search_key: 'mobile', search_val: '', shop_title: '', spread_code: '',  status: 'ALL', page:1, limit: 15 },

        table:{ loading:false, total:0, rows:[] },

        form: false,
        radio: 1,
        areaCodeSelected: [],

        formData: {},

        commissionShow: true,
        principalShow: true,

        shopTypeList: []
      }
    },
    async created() {
      await this.apiGetShopTypeListHandel()
      this.getList()
    },
    filters: {
      filtersSex(sex) {
        if(sex == 1) return '男'
        return '女'
      },
      filtersSpread(obj) {
        if(!obj.id) return '无'
        if(obj.type == 1) return `买家-${obj.mobile}`
        if(obj.type == 2) return `商家-${obj.mobile}`
        if(obj.type == 3) return `代理-${obj.mobile}`
        return ''
      }
    },
    methods: {
      formatDate,
      // 新增商家
      addBussiness() {
        this.$refs.BussinessListAddPop.onShow()
      },
      // 登录商家店店铺
     async apiGetUerLoginHandel({ id }) {
        let loading = this.$loading()
        const { success, data } = await apiGetUerLogin({ id}).catch(loading.close())
        loading.close()
        if(!success) return
        window.open(data.url)
      },
       //修改商家余额
      changeMoneyClick(item) {
        this.$refs.BussinessChangeMoneyPop.onShow(item)
      },

      // 封号/解封
      async apiSetShopStatusHandel({ id }, status) {
        let str = status == 0 ? '此操作将该商家封号, 是否继续' : '此操作将该商家解封, 是否继续'
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
            this.table.loading = true
            const { success, data } = await apiSetShopStatus({ id, status }).catch(() =>  this.table.loading = false)
            this.table.loading = false
            if(!success) return
            this.$message.success(data.msg)
            this.getList()
        }).catch(() => {});
       
      },

    // 获取店铺类型
    async apiGetShopTypeListHandel() {
      const { success, data } = await apiGetShopTypeList()
      if(!success) return
      this.shopTypeList = data.list
      console.log(data)
    },

      // 开关变化监听
      switchChange(item) {
        this.apiSetBuySpreadStatusHandel(item)
      },

      // 修改商家返款类型
      async apiShopUserEditHandel(item) {
        this.table.loading = true
        const { success, data } = await apiShopUserEdit({ id: item.id, fk_mode: item.fk_mode }).catch(() => {
          if(item.fk_mode == 0) item.fk_mode = 1
          if(item.fk_mode == 1) item.fk_mode = 0
          this.table.loading = false
        })
        if(!success) {
          if(item.fk_mode == 0) return item.fk_mode = 1
          if(item.fk_mode == 1) return item.fk_mode = 0
        }
        this.table.loading = false
        this.$message.success(data.msg)
        this.getList()
        console.log(data)
      },

      // 推广状态
      async apiSetBuySpreadStatusHandel(item) {
        this.table.loading = true
        const { success, data } = await apiSetShopSpreadStatus({ id: item.id, status: item.spread_status }).catch(() => {
          if(item.spread_status == 0) item.spread_status = 1
          if(item.spread_status == 1) item.spread_status = 0
          this.table.loading = false
        })
        if(!success) {
          if(item.spread_status == 0) return item.spread_status = 1
          if(item.spread_status == 1) return item.spread_status = 0
        }
        this.table.loading = false
        this.$message.success(data.msg)
        this.getList()
        console.log(data)
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetBussinessList(this.query).catch(() => this.table.loading = false)
        if(!success) return
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: ''  }, status) {
        this.formData = JSON.parse(JSON.stringify(formData))
        this.$refs.BussinessListPop.onShow(formData, status)
      },
      // 点击重置
      resetData() {
        this.query = { search_key: 'mobile', search_val: '', shop_title: '', spread_code: '',  status: 'ALL', page:1, limit: 15 }
        this.getList()
      }
      
    }
  }
</script>

<style lang="scss">
.v_user_list {
  .v-user-money-item-edit {
    span {
      font-size: 14px;
    }
    i {
      cursor: pointer;
      padding: 0 20px;
      font-size: 16px;
    }
  }
}

</style>