<template>
 <div class="s_user_cash_out_list_pop">
    <el-dialog :close-on-press-escape="false" :title="'编辑详情'" class="s-task-dialog" top="30px" width="1100px" :close-on-click-modal="false" :visible.sync="show">
                <el-form :model="formData" label-width="140px" size="mini">
                  <el-tabs v-model="activeName" type="card">
                    <!-- 提现信息 -->
                    <el-tab-pane label="商家信息" name="1">
                      <el-row>
                          <el-col :span="8">
                            <el-form-item label="商家ID:">
                              <span>{{ formData.id }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="商家手机号:">
                              <span>{{formData.mobile}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                         <el-row>
                          <el-col :span="8">
                            <el-form-item label="商家余额:">
                              <span>{{ formData.principal }}</span>
                            </el-form-item>
                          </el-col>
                           <el-col :span="16">
                            <el-form-item label="商家推广码:">
                              <span>{{formData.spread_code}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="QQ号:">
                              <el-input placeholder="请输入内容" v-model="formData.qq" clearable></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="微信号:">
                              <el-input placeholder="请输入内容" v-model="formData.wechat" clearable></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                         
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="推广状态:">
                              <span>{{ formData.spread_status == 1 ? '开启': '关闭' }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="状态:">
                              <span>{{formData.status == 1 ? '正常': '封号' }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                           <el-col :span="8">
                          <el-form-item label="密码:">
                            <el-input placeholder="不修改不填写此项" v-model="formData.password" show-password></el-input>
                            <span class="g-red">6-20的数字字母组合</span>
                          </el-form-item>
                        </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="最后登录时间:">
                              <span>{{formatDate(formData.login_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="创建时间:">
                              <span>{{formatDate(formData.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 资金变动记录 -->
                    <el-tab-pane label="店铺列表" name="2" class="s-tabs-content-two">
                      <el-table v-loading="table.loading" class="g-self-scrollbar s-user-cash-ou-list-pop-table" ref="singleTable"  size="small" :data="table.rows" highlight-current-row  style="width: 100%">
                        <el-table-column prop="platform_id" label="店铺类型" width="80" :show-overflow-tooltip="true">
                          <template slot-scope="scope">
                            <span> {{ shopTypeList.find(item => { return scope.row.platform_id === item.id }) ? shopTypeList.find(item => { return scope.row.platform_id === item.id }).title : '' }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column prop="title" label="店铺名称" width="150" :show-overflow-tooltip="true">
                        </el-table-column>
                          <el-table-column prop="name" label="平台账号" width="150" :show-overflow-tooltip="true">
                        </el-table-column>
                        <el-table-column prop="url" label="店铺链接" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="店铺后台图片" width="140" :show-overflow-tooltip="true">
                          <template slot-scope="scope">
                            <el-image  style="width: 100px; height: 100px" fit="cover" :src="scope.row.logo" :preview-src-list="[scope.row.logo]"></el-image>
                          </template>
                        </el-table-column>
                        <el-table-column label="创建时间" width="140" :show-overflow-tooltip="true">
                          <template slot-scope="scope">
                            <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="发货地址"  prop="region" width="240" :show-overflow-tooltip="true"> </el-table-column>
                          <el-table-column label="状态" :show-overflow-tooltip="true">
                          <template slot-scope="scope">
                            <span class="g-red" v-show="scope.row.status === 0">审核未通过 <span>({{scope.row.remark}})</span></span>
                            <span class="g-yellow" v-show="scope.row.status === 1">审核中</span>
                            <span class="g-green" v-show="scope.row.status === 2">审核通过</span>
                          </template>
                        </el-table-column>
                      </el-table>

                      <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
                    </el-tab-pane>
                  </el-tabs>

                  <el-row class="s-user-remark">
                    <el-col :span="18">
                      <el-form-item label="备注:">
                         <el-input placeholder="请输入备注" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>

        <div slot="footer" class="dialog-footer">

            <el-button @click="show = false" v-show="seeStatus === 2">取 消</el-button>
            <el-button type="primary" @click="apiShopUserEditHandel" v-show="seeStatus === 2">确定</el-button>
        </div>
    </el-dialog>

 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js'
import { apiGetShopList, apiShopUserEdit } from '@/utils/api.js'
export default {
  props: {
    shopTypeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      seeStatus: 1, //1 审核 2查看
      query: {
        page:1, limit: 15, user_id: ''
      },

      table:{ loading:false, total:0, rows:[] },
      
      formData: {
        id: '',
        password: ''
      },
      show: false,
      activeName: '1',
      // 任务回收时间
      taskTimeList: [
        { name: '2小时', value: 2 },
        { name: '4小时', value: 4 },
        { name: '8小时', value: 8 },
        { name: '12小时', value: 12 },
        { name: '24小时', value: 24 },
        { name: '48小时', value: 48 },
        { name: '当天24点', value: 0 },
      ],
    }
  },
  methods: {
    formatDate,
    // 修改商家信息
    async apiShopUserEditHandel() {
      let loading = this.$loading()
      const { success, data } = await apiShopUserEdit(this.formData).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitSaveSuccess')
      this.show = false
      console.log(data)
    },
    // 店铺信息
    async getList(pageInit = true) {
      if (pageInit) this.query.page = 1
      this.table.loading = true
      const { success, data } = await apiGetShopList(this.query)
      if(!success) return
      this.table.loading = false
      this.table.rows = data.rows
      this.table.total = data.total
      console.log(data)
    },
    //每页数量改变
    sizeChange(limit) {
      this.query.limit = limit
      this.getList()
    },
    // status //1 审核 2查看
    onShow(data, status) {
      console.log(data.id)
      this.seeStatus = status
      console.log(this.formData)
      this.formData.password = ''
      this.formData = Object.assign({}, this.formData, data)
      this.query.user_id = data.id
      this.getList(data)
      this.show = true
    },
    onClose() {
      this.show = false
    }
  },
  filters: {
      filtersType(type) {
        if(type == 1) return '充值'
        if(type == 2) return '提现'
        if(type == 3) return '任务'
        if(type == 4) return '订单'
        if(type == 5) return '推广'
        return '其他'
      }
    },
}
</script>

<style lang='scss'>
.s_user_cash_out_list_pop {
  .el-dialog {
    max-height: 920px;
    margin: 0 auto;
  }
  .s-tabs-content-two {
    padding-bottom: 20px;
  }
  .s-user-cash-ou-list-pop-table {
    height: 500px;
    overflow: auto;
  }
  .s-user-remark {
    margin-top: 10px;
  }
}
</style>