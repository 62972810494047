<template>
  <div class="c_user_list_change_money_pop">
    <el-dialog title="新增商家" width="600px" :close-on-click-modal="false" :visible.sync="show">
        <el-form ref="form" :rules="rules" size="small" :model="form" label-width="150px">
          <el-row>
            <el-col :span="18">
               <el-form-item label="手机号:" prop="mobile">
                <el-input type="text" placeholder="请输入手机号" v-model="form.mobile"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
               <el-form-item label="登录密码:" prop="password">
                <el-input type="password" placeholder="请输入登录密码" v-model="form.password" show-password></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
               <el-form-item label="确认登录密码:" prop="password">
                <el-input type="password" placeholder="请再次输入登录密码" v-model="form.password2" show-password></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
               <el-form-item label="QQ号:">
                <el-input type="text" placeholder="请输入QQ号" v-model="form.qq"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
               <el-form-item label="微信号:">
                <el-input type="text" placeholder="请输入微信号" v-model="form.wechat"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
               <el-form-item label="邀请码:">
                <el-input type="text" placeholder="请输入邀请码" v-model="form.tcode"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="onClose">取 消</el-button>
          <el-button type="primary" @click="apiAddBusinessHandel">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiAddBusiness } from '@/utils/api.js'
export default {
  data() {
    return {
      show: false,
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
          mobile: [
              {required: true, message: '手机号不可为空', trigger: 'blur'}
          ],
          mobile_code: [
              {required: true, message: '验证码不可为空', trigger: 'blur'}
          ],
          password: [
              {required: true, message: '密码不可为空', trigger: 'blur'}
          ],
          password2: [
              {required: true, message: '密码不可为空', trigger: 'blur'}
          ]
      },
      form: {
          mobile: '',
          mobile_code: '',
          password: '',
          password2: '',
          tcode: '',
          qq: '',
          wechat: ''
      },
    }
  },
  methods: {
    // 新增商家
    async apiAddBusinessHandel() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
            if(this.form.password !== this.form.password2) return this.$message.error('请保证两次密码输入一致!')
            const loading = this.$loading()
            const { success, data } = await apiAddBusiness(this.form).catch(()=> { loading.close() })
            loading.close()
            if(!success) return 
            this.$message.success(data.msg)
            this.onClose()
            this.$emit('emitSaveSuccess')
          } else {
            return false;
          }
      })
    },

    // status //1 修改本金余额 2修改佣金余额
    onShow() {
      this.show = true
    },

    onClose() {
      this.form = {
          mobile: '',
          mobile_code: '',
          password: '',
          password2: '',
          tcode: '',
          qq: '',
          wechat: ''
      }
      this.show = false
    }
  }
}
</script>

<style lang='scss'>
</style>